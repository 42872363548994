import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const SendedIcon = ({
    size = 22,
    color = "#000",
    secondaryColor = "#fff",
    onClick,
    className,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        {...props}
    >
        <path
            d="M360.209 15.474a11.276 11.276 0 0 0-2.496-12.17 11.284 11.284 0 0 0-12.17-2.496L17.323 132.1H17.3l-10.198 4.061a11.277 11.277 0 0 0-7.054 9.44 11.281 11.281 0 0 0 5.203 10.573l9.251 5.866.023.046 112.701 71.704 35.875 56.362c17.418 25.721 17.418 3.159 17.418-8.123A101.531 101.531 0 0 1 293.92 181.175l66.289-165.701Zm-41.358 42.643L149.766 227.203l-4.851-7.626a11.275 11.275 0 0 0-3.475-3.475l-7.626-4.851L302.9 42.165l26.579-10.627-10.605 26.58h-.023Z"
            fill={color}
            style={{
                transform: `scale(${size / 362})`,
            }}
        />
        <path
            d="M361.017 282.031a78.97 78.97 0 1 1-157.939 0 78.97 78.97 0 0 1 157.939 0Zm-44.968-37.883a11.27 11.27 0 0 0-8.543-1.266 11.276 11.276 0 0 0-6.935 5.147l-26.398 43.997-12.342-12.342a11.295 11.295 0 0 0-15.974 15.975L263.32 313.1a16.925 16.925 0 0 0 26.489-3.249l30.121-50.225a11.274 11.274 0 0 0 1.266-8.543 11.28 11.28 0 0 0-5.147-6.935Z"
            fill={color}
            style={{
                transform: `scale(${size / 362})`,
            }}
        />
    </svg>;
};
export default SendedIcon;
