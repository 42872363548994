import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const DiscordIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    style,
    onClick,
    className,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={14}
        height={16}
        fill="none"
        className={className}
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        onClick={onClick}
        {...props}
    >
        <path
            d="M5.494 6.642c-.452 0-.807.396-.807.88 0 .481.364.879.807.879.45 0 .807-.398.807-.88.009-.484-.356-.879-.807-.879Zm2.89 0c-.452 0-.807.396-.807.88 0 .481.364.879.807.879.45 0 .807-.398.807-.88-.001-.484-.356-.879-.807-.879Z"
            fill={color}
        />
        <path
            d="M12.232 0H1.622A1.627 1.627 0 0 0 0 1.63v10.704c0 .903.728 1.631 1.623 1.631H10.6l-.42-1.466 1.013.943.958.886 1.703 1.505V1.631A1.627 1.627 0 0 0 12.232 0ZM9.175 10.34s-.285-.34-.522-.643c1.037-.294 1.433-.942 1.433-.942a4.586 4.586 0 0 1-.911.468 5.566 5.566 0 0 1-3.198.332 6.62 6.62 0 0 1-1.164-.342 4.502 4.502 0 0 1-.577-.267c-.024-.018-.048-.024-.071-.04a.097.097 0 0 1-.032-.025c-.143-.079-.222-.133-.222-.133s.38.633 1.386.934c-.238.3-.531.657-.531.657-1.75-.056-2.413-1.203-2.413-1.203 0-2.55 1.14-4.615 1.14-4.615 1.14-.856 2.224-.832 2.224-.832l.079.095c-1.425.413-2.083 1.038-2.083 1.038s.175-.095.468-.23c.847-.371 1.519-.474 1.797-.499.047-.007.087-.016.135-.016a6.463 6.463 0 0 1 3.981.745s-.626-.594-1.971-1.005l.11-.127s1.085-.024 2.224.832c0 0 1.14 2.066 1.14 4.615 0-.001-.672 1.146-2.422 1.202Z"
            fill={color}
        />
    </svg>;
};
export default DiscordIcon;
