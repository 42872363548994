import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        userSelect: "none",
        display: "flex"
    },
    contentContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex"
    },
    titleContainer: {
        flexDirection: "column",
        display: "flex"
    },
    title: {
        borderBottomStyle: "solid",
        alignSelf: "baseline",
        borderBottomWidth: 1
    }
}, {
    name: "Project-Landing-Page-Our-Team"
});
export default useStyles;
