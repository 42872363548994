import {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./style";
import IHeaderProps from "./props";
import {
    useNCoreTheme,
    Text
} from "ncore-web";
import LanguageSwitcher from "../languageSwitcher";
import ThemeSwitcher from "../themeSwitcher";
import Menu from "../menu";
import $ from "jquery";
import {
    MenuIcon 
} from "../../assets/svgr";

const Header = ({
    setIsActiveMenu,
    isActiveMenu,
    height = 75,
    width = 75,
    dimensions,
    subTitle,
    logoURL,
    title,
    data
}: IHeaderProps) => {
    const {
        colors,
        spaces
    } = useNCoreTheme();

    const classes = useStyles();
    const titleRef = useRef(null);
    const logoRef = useRef(null);

    const [currentState, setCurrentState] = useState(window.location.hash);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("popstate", hashUpdate);
        // @ts-ignore
        $(titleRef.current).ready(() => {
            // @ts-ignore
            $(titleRef.current).addClass("loadAnimate");
            // @ts-ignore
            $(logoRef.current).addClass("loadAnimate");
        });
        const hash = window.location.hash === "" ? "#" : window.location.hash;
        setCurrentState(hash);
        window.addEventListener("scroll", scrolled);
        return () => {
            window.removeEventListener("popstate", hashUpdate);
            window.removeEventListener("scroll", scrolled);
        };
    }, []);

    const scrolled = (event: Event) => {
        // @ts-ignore
        const scrollPos = event.target.scrollingElement.scrollTop;
        if(scrollPos > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const hashUpdate = (event: any) => {
        const hash = event.target.location.hash === "" ? "#" : event.target.location.hash;
        setCurrentState(hash);
    };

    const renderMenu = () => {
        return <div
            className={classes.rightContainer}
        >
            <Menu
                data={data}
            />
            {
                isScrolled ?
                    null
                    :
                    <div
                        className={classes.toolsContainer}
                    >
                        <ThemeSwitcher/>
                        <LanguageSwitcher/>
                    </div>
            }
        </div>;
    };

    let _width = width;
    let _height = height;

    switch(true) {
    case dimensions.width < 1200 && dimensions.width > 750:
        _width = width / 1.25;
        _height = height / 1.25;
        break;
    case dimensions.width < 750:
        _width = width / 1.5;
        _height = height / 1.5;
        break;
    }

    const logoScale = dimensions.width < 1200 ? 1.25 : 1.5;

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer2 + "C5",
            padding: spaces.container,
            color: colors.primary
        }}
    >
        <div
            className={classes.header}
            onClick={() => {
                window.location.href = "/#";
            }}
        >
            <img
                className={classes.logo}
                ref={logoRef}
                src={logoURL}
                width={isScrolled ? _width / logoScale : _width}
                height={isScrolled ? _height / logoScale : _height}
            />
            <div
                ref={titleRef}
                className={classes.titleContainer}
                style={{
                    marginLeft: spaces.content
                }}
            >
                <Text
                    variant="header3"
                    color="primary"
                    className={classes.title}
                >
                    {title}
                </Text>
                {
                    subTitle && !isScrolled && dimensions.width > 1200 ?
                        <Text
                            variant="header7"
                            color="gray20"
                        >
                            {subTitle}
                        </Text>
                        :
                        null
                }
            </div>
        </div>
        {
            dimensions.width > 1200 ?
                renderMenu()
                :
                <div
                    className={classes.menuContainer}
                    onClick={() => {
                        setIsActiveMenu(true);
                    }}
                >
                    <MenuIcon
                        color={colors.body}
                        className={classes.menu}
                        size={22}
                    />
                </div>
        }
    </div>;
};
export default Header;
