import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        userSelect: "none",
        width: "100%"
    },
    titleContainer: {
        flexDirection: "column",
        display: "flex"
    },
    title: {
        borderBottomStyle: "solid",
        alignSelf: "baseline",
        borderBottomWidth: 1
    },
    contentContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 1200px)": {
            flexDirection: "column"
        }
    },
    content: {
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    image: {
        objectFit: "cover",
        height: 300
    },
    communityLogo: {
        width: "auto",
        height: 350,
        "@media screen and (max-width: 1200px)": {
            width: "100%",
            height: "auto"
        }
    }
}, {
    name: "Project-Landing-Page-Community-Section"
});
export default useStyles;
