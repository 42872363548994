import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        userSelect: "none",
        display: "flex",
        height: "auto",
        width: "100%"
    },
    title: {
        borderBottomStyle: "solid",
        alignSelf: "baseline",
        borderBottomWidth: 1
    },
    cardsContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        overflow: "hidden",
        flexWrap: "wrap",
        display: "flex"
    },
    card: {
        transform: "translateY(150px)",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        width: "31%",
        opacity: 0,
        "& > div": {
            flexDirection: "column",
            overflow: "hidden",
            display: "flex"
        },
        "& > div > div": {
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            objectFit: "cover",
            width: "100%",
            height: 300,
            "@media screen and (max-height: 750px)": {
                height: 185
            }
        },
        "& > div > div:hover": {
            transform: "scale(2)",
            transition: "none"
        },
        "&.active": {
            transform: "translateY(0)",
            opacity: 1
        },
        "@media screen and (max-width: 1200px)": {
            width: "100%"
        }
    },
    cardIconContainer: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        minHeight: 150,
        width: "100%"
    },
    textsContainer: {
        justifyContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    titleContainer: {
        flexDirection: "column",
        display: "flex"
    }
});
export default useStyles;
