import {
    INCoreIconProps
} from "ncore-web";

const SunIcon = ({
    size = 1,
    color = "#000",
    ...props
}: INCoreIconProps) => {
    return <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`
        }}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 4a.75.75 0 0 1 .75.75v.75a.75.75 0 1 1-1.5 0v-.75A.75.75 0 0 1 10 4Zm3 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-.348 3.713.53.53a.75.75 0 0 0 1.06-1.06l-.53-.531a.75.75 0 0 0-1.06 1.06Zm1.59-7.956a.75.75 0 0 1 0 1.06l-.53.531a.75.75 0 1 1-1.06-1.06l.53-.53a.75.75 0 0 1 1.06 0Zm1.008 4.993a.75.75 0 1 0 0-1.5h-.75a.75.75 0 1 0 0 1.5h.75Zm-5.25 3a.75.75 0 0 1 .75.75v.75a.75.75 0 1 1-1.5 0v-.75a.75.75 0 0 1 .75-.75ZM6.287 7.348a.75.75 0 1 0 1.062-1.06l-.531-.53a.75.75 0 0 0-1.06 1.06l.53.53Zm1.061 6.365-.53.53a.75.75 0 0 1-1.06-1.06l.53-.531a.75.75 0 0 1 1.06 1.06ZM5.5 10.75a.75.75 0 1 0 0-1.5h-.75a.75.75 0 0 0 0 1.5h.75Z"
            fill={color}
        />
    </svg>;
};
export default SunIcon;
