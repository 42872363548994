import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Button
} from "ncore-web";
import {
    LANGUAGE_ICONS,
    LANGUAGES
} from "../../constants/languages";

const LanguageSwitcher = () => {
    const {
        switchLocale,
        activeLocale,
        localize
    } = useNCoreLocalization();
    const {
        spaces
    } = useNCoreTheme();

    const classes = useStyles();

    return <div
        className={classes.container}
        style={{
            marginRight: spaces.content
        }}
    >
        <Button
            icon={() => LANGUAGE_ICONS[activeLocale]({
                size: 1.5
            })}
            color="body"
            title={localize("current-language")}
            size="small"
            variant="ghost"
            onClick={() => {
                const currentLanguageIndex = LANGUAGES.findIndex(e => e === activeLocale);
                const nextLanguageIndex = currentLanguageIndex === LANGUAGES.length - 1 ? 0 : currentLanguageIndex + 1;
                const lang = LANGUAGES[nextLanguageIndex];
                switchLocale(lang);
                window.localStorage.setItem("lang", lang);
            }}
        />
    </div>;
};
export default LanguageSwitcher;
