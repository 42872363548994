import {
    CSSProperties
} from "react";
import {
    INCoreIconProps
} from "ncore-web";

const MailIcon = ({
    size = 1,
    color = "#000",
    style,
    ...props
}: INCoreIconProps & {
    style?: CSSProperties
}) => {
    return <svg
        width={16}
        height={11}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        {...props}
    >
        <path
            d="m.51 1.179 6.667 3.693a1.7 1.7 0 0 0 .805.183 1.7 1.7 0 0 0 .806-.183l6.666-3.693C15.89.938 16.3 0 15.502 0H.463C-.334 0 .076.938.51 1.179Zm15.146 2.02-6.868 3.69c-.303.164-.514.183-.806.183-.291 0-.503-.02-.805-.182C6.875 6.727.837 3.462.343 3.197-.004 3.01 0 3.23 0 3.398v6.685c0 .385.503.917.889.917H15.11c.386 0 .889-.532.889-.917V3.4c0-.169.004-.388-.344-.2Z"
            fill={color}
        />
    </svg>;
};
export default MailIcon;
