import {
    ReactNode,
    useEffect,
    useState,
    FC
} from "react";
import {
    useNCoreLocalization,
    useNCoreTheme,
    NCoreProvider,
} from "ncore-web";
import useStyles from "./style";
import Header from "./components/header";
import MAIN_MENU from "./constants/mainMenu";
import locales from "./locales";
import themes from "./themes";
import Navigation from "./navigation";
import {
    getBrowserLanguage,
    getBrowserTheme
} from "./utils";
import GlobalStateProvider from "./context";
import Footer from "./components/footer";
import MobileMenu from "./components/mobileMenu";

const App = () => {
    const classes = useStyles();

    const {
        colors
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    const [isActiveMenu, setIsActiveMenu] = useState(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        window.addEventListener("resize", resized);
        return () => {
            window.removeEventListener("scroll", resized);
        };
    }, []);

    const resized = (event: Event) => {
        // @ts-ignore
        const height = event.target.window.innerHeight;
        // @ts-ignore
        const width = event.target.window.innerWidth;

        setDimensions({
            height: height,
            width: width
        });
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer1
        }}
    >
        <Header
            title="NİBGAT®"
            subTitle={localize("slogan")}
            logoURL={`/assets/images/ndarklogoforanim.png`}
            data={MAIN_MENU}
            dimensions={dimensions}
            isActiveMenu={isActiveMenu}
            setIsActiveMenu={setIsActiveMenu}
        />
        <Navigation/>
        {
            window.location.pathname === "" || window.location.pathname === "/" || window.location.pathname === "#" || window.location.pathname === "/#" ?
                null
                :
                <Footer/>
        }
        {
            dimensions.width < 1200 ?
                <MobileMenu
                    isActiveMenu={isActiveMenu}
                    setIsActiveMenu={setIsActiveMenu}
                    dimensions={dimensions}
                    data={MAIN_MENU}
                />
                :
                null
        }
    </div>;
};

const GlobalStateContext: FC<{
    children: ReactNode
}> = ({
    children
}) => {
    return <GlobalStateProvider>
        {children}
    </GlobalStateProvider>;
};

const NCoreContext = () => {
    const browserLang: string = getBrowserLanguage();
    const borwserTheme = getBrowserTheme();

    return <NCoreProvider
        config={{
            locales: locales,
            themes: themes,
            initialLanguage: browserLang,
            initialThemeKey: borwserTheme || "light"
        }}
    >
        <GlobalStateContext>
            <App/>
        </GlobalStateContext>
    </NCoreProvider>;
};
export default NCoreContext;
