import {
    Routes,
    Route
} from "react-router-dom";
import GetOffer from "../pages/getOffer";
import LandingPage from "../pages/landing";

const Navigation = () => {
    return <Routes>
        <Route
            path="/"
            element={<LandingPage/>}
        />
        <Route
            path="/get-offer"
            element={<GetOffer/>}
        />
    </Routes>;
};
export default Navigation;
