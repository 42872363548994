import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "flex-end",
        userSelect: "none",
        position: "fixed",
        display: "flex",
        height: "100%",
        width: "100%",
        zIndex: 99999,
        bottom: 0,
        right: 0,
        top: 0
    },
    content: {
        flexDirection: "column",
        display: "flex",
        height: "100%",
        width: "80%"
    },
    header: {
        justifyContent: "space-between",
        transition: "transform 0.1s",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    closeButton: {
        "&:hover": {
            transition: "transform 0.1s",
            cursor: "pointer",
            opacity: 0.75
        },
        "&:active": {
            transform: "translateY(2px)",
            transition: "transform 0.1s"
        }
    },
    buttonsContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignSelf: "stretch",
        display: "flex",
        width: "100%",
        flexGrow: 1
    },
    item: {
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        }
    },
    buttons: {
        flexDirection: "column",
        display: "flex",
        height: "100%"
    },
    toolsContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    }
}, {
    name: "Project-Mobile-Menu"
});
export default useStyles;
