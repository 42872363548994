import {
    CSSProperties
} from "react";
import {
    INCoreIconProps
} from "ncore-web";

const PhoneIcon = ({
    size = 1,
    color = "#000",
    style,
    ...props
}: INCoreIconProps & {
    style?: CSSProperties
}) => {
    return <svg
        width={17}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        {...props}
    >
        <path
            d="M15.344 2.543 13.264.465a1.58 1.58 0 0 0-2.24 0L8.784 2.703a1.579 1.579 0 0 0 0 2.24l1.637 1.637A7.692 7.692 0 0 1 8.85 8.848a7.723 7.723 0 0 1-2.268 1.578L4.945 8.789a1.579 1.579 0 0 0-2.24 0l-2.24 2.236A1.58 1.58 0 0 0 0 12.146c0 .424.166.823.465 1.122l2.076 2.076a2.567 2.567 0 0 0 2.225.715c2.632-.434 5.244-1.834 7.351-3.94 2.106-2.103 3.504-4.713 3.944-7.353a2.57 2.57 0 0 0-.717-2.223Z"
            fill={color}
        />
    </svg>;
};
export default PhoneIcon;
