import {
    createContext,
    useReducer,
    useContext,
    Dispatch
} from "react";
import GLOBAL_STATE_INITIAL_STORE from "../constants/globalState";
import IGlobalState, {
    GlobalStateProviderProps 
} from "./type";

const GlobalStateContext = createContext<IGlobalState>(GLOBAL_STATE_INITIAL_STORE);
const GlobalStateDispatchContext = createContext<Dispatch<Partial<IGlobalState>>>(() => {});

const GlobalStateProvider = ({
    children
}: GlobalStateProviderProps) => {
    const [state, setState]: [IGlobalState, Dispatch<Partial<IGlobalState>>] = useReducer((state: IGlobalState, newState: Partial<IGlobalState>) => ({
        ...state,
        ...newState
    }),
    GLOBAL_STATE_INITIAL_STORE);

    return <GlobalStateContext.Provider
        value={state}
    >
        <GlobalStateDispatchContext.Provider
            value={setState}
        >
            {children}
        </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>;
};
export const useGlobalState = (): [IGlobalState, Dispatch<Partial<IGlobalState>>] => [
    useContext(GlobalStateContext),
    useContext(GlobalStateDispatchContext)
];
export default GlobalStateProvider;
