import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    "@global": {
        "@keyframes boublerotate": {
            from: {
                transform: "rotate(0deg)"
            },
            to: {
                transform: "rotate(360deg)"
            }
        }
    },
    container: {
        userSelect: "none",
        minHeight: "100%",
        width: "100%"
    },
    ncoreContainer: {
        paddingBottom: "0px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
        paddingTop: "0px !important",
        padding: "0px !important",
    },
    contentContainer: {
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        display: "flex",
        height: "100%",
        width: "100%",
        zIndex: 99,
        "@media screen and (max-width: 1200px)": {
            overflow: "hidden"
        }
    },
    title: {
        borderBottomStyle: "solid",
        alignSelf: "baseline",
        borderBottomWidth: 1
    },
    content: {
        transform: "translateX(-150px)",
        flexDirection: "column",
        display: "flex",
        maxWidth: "50%",
        opacity: 0,
        "&.active": {
            transform: "translateX(0)",
            opacity: 1
        },
        "@media only screen and (max-width: 1200px)": {
            maxWidth: "100%"
        }
    },
    bouble: {
        justifyContent: "center",
        transform: "scale(0)",
        position: "absolute",
        alignItems: "center",
        borderRadius: 150,
        display: "flex",
        height: 300,
        width: 300,
        opacity: 0,
        "&.active": {
            transform: "scale(1)",
            opacity: 1
        },
        "& > div > div": {
            position: "absolute",
            borderStyle: "solid",
            borderRadius: 10,
            borderWidth: 1,
            height: 20,
            width: 20
        },
        "&:nth-child(1)": {
            right: "15%",
            top: "37%",
            "@media screen and (max-height: 650px)": {
                top: "30%"
            },
            "@media only screen and (max-width: 1200px)": {
                opacity: 0.5,
                top: "30%",
                right: "10%"
            }
        }
    }
}, {
    name: "Project-Landing-About-Section"
});
export default useStyles;
