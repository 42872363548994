import useStyles from "./style";
import ITeamCard from "./props";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Text
} from "ncore-web";
import {
    InstagramIcon,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    MediumIcon,
    GithubIcon,
    UpworkIcon
} from "../../../../../../assets/svgr";
import {
    SocialLink 
} from "../../props";

const TeamCard = ({
    profileImage,
    appellation,
    socialLinks,
    dimensions,
    fullName,
    title,
    id
}: ITeamCard) => {
    const {
        localize
    } = useNCoreLocalization();
    const {
        radiuses,
        spaces,
        colors
    } = useNCoreTheme();

    const classes = useStyles();

    const iconColor = colors.constrastBody;
    const iconBGcOLOR = colors.body;

    const socialIcon = (item: SocialLink, index: number) => {
        switch(item.icon) {
        case "facebook":
            return <FacebookIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
            />;
        case "github":
            return <GithubIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        case "instagram":
            return <InstagramIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        case "linkedin":
            return <LinkedinIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        case "medium":
            return <MediumIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        case "twitter":
            return <TwitterIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        case "upwork":
            return <UpworkIcon
                key={`${appellation}-social-icon-${index}`}
                className={classes.icon}
                color={iconColor}
                secondaryColor={iconBGcOLOR}
                onClick={() => {
                    window.open(item.url, '_blank');
                }}
                style={{
                    marginRight: spaces.content / 2,
                    marginLeft: spaces.content / 2
                }}
            />;
        }
    };

    return <div
        id={id}
        className={classes.container}
        style={{
            backgroundColor: colors.panel,
            borderRadius: radiuses.half,
            padding: spaces.container,
            marginBottom: dimensions.width < 1200 ? spaces.content * 2 : 0
        }}
    >
        <img
            src={`/assets/images/team/${profileImage}`}
            className={classes.profileImage}
            style={{
                marginBottom: spaces.content * 2
            }}
        />
        <div
            className={classes.content}
        >
            <Text
                variant="header7"
                style={{
                    marginBottom: spaces.content
                }}
            >
                {fullName}
            </Text>
            <Text
                variant="header9"
            >
                {localize(title)}
            </Text>
            <Text>
                {appellation}@nibgat
            </Text>
        </div>
        <div
            className={classes.socialLinksContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            {
                socialLinks ?
                    socialLinks.map((item, index) => {
                        return socialIcon(item, index);
                    })
                    :
                    null
            }
        </div>
    </div>;
};
export default TeamCard;
