import {
    useEffect,
    useState
} from "react";
import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Container,
    Text
} from "ncore-web";
import $ from "jquery";
import ILandingServices from "./props";
import ServiceCard from "./components/serviceCard";

export type OurServicesType = {
    content: keyof NCore.Translation;
    title: keyof NCore.Translation;
    cover: string;
};

export const OUR_SERVICES: Array<OurServicesType> = [
    {
        title: "landing-services-service-1",
        content: "landing-services-service-1-description",
        cover: "/assets/images/services/ideatoproduct.png"
    },
    {
        title: "landing-services-service-2",
        content: "landing-services-service-2-description",
        cover: "/assets/images/services/techstack.png"
    },
    {
        title: "landing-services-service-3",
        content: "landing-services-service-3-description",
        cover: "/assets/images/services/nexp.png"
    }
];

const Services = ({
    scrollData,
    dimensions
}: ILandingServices) => {
    const {
        colors,
        spaces
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    const [isCardReady, setIsCardReady] = useState(false);

    useEffect(() => {
        mouseMoveListener();
        setAnimations();
        if(dimensions.width < 1200) {
            for (var i = 0; i < 3; i++) {
                $(`#service-card-${i}`).addClass("active");
            }
        }
    }, []);

    useEffect(() => {
        setAnimations();
    }, [scrollData]);

    useEffect(() => {
        mouseMoveListener();
    }, [dimensions]);

    const setAnimations = () => {
        if(dimensions.width > 1200) {
            const {
                scrollHeight,
                offsetHeight,
                scrollTop
            } = scrollData;

            const elementVisible = 200;

            if(isCardReady) {
                for (var i = 0; i < 3; i++) {
                    const elementTop = $(`#service-card-${i}`).position().top;
                    const percent = ((elementTop - elementVisible) * 100) / scrollHeight;
                    const scrollTotal = scrollHeight - offsetHeight;
                    const scrollPosition = (scrollTotal * percent) / 100;

                    if (scrollTop > scrollPosition) {
                        $(`#service-card-${i}`).addClass("active");
                    } else {
                        $(`#service-card-${i}`).removeClass("active");
                    }
                }
            }
        } else {
            for (var i = 0; i < 3; i++) {
                $(`#service-card-${i}`).addClass("active");
            }
        }
    };

    const mouseMoveListener = () => {
        $("#service-card-2").ready(() => {
            setIsCardReady(true);
            for(let i = 0; i < 3; i++) {
                const height = $(`#service-card-${i} > div > div`).height() || 0;
                const width = $(`#service-card-${i} > div > div`).width() || 0;

                $(`#service-card-${i} > div > div`).mousemove((event) => {
                    const x = -event.offsetX + (width / 2);
                    const y = -event.offsetY + (height / 2);

                    $(`#service-card-${i} > div > div`).css("background-position-x", x + "px");
                    $(`#service-card-${i} > div > div`).css("background-position-y", y + "px");
                });
                $(`#service-card-${i} > div > div`).mouseleave((event) => {
                    $(`#service-card-${i} > div > div`).css("background-position", "center");
                });
            }
        });
    };

    return <Container
        id="services"
        contentClassName={classes.container}
        contentContainerStyle={{
            paddingRight: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4,
            paddingLeft: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4
        }}
    >
        <div
            className={classes.titleContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content * 10
            }}
        >
            <Text
                className={classes.title}
                variant="header2"
                style={{
                    borderBottomColor: colors.primary,
                    marginBottom: spaces.content * 2,
                    paddingRight: spaces.inline * 8,
                    paddingBottom: spaces.inline
                }}
            >
                {localize("landing-services-section-title")}
            </Text>
            <Text>
                {localize("landing-services-section-description")}<br />
                {localize("landing-services-section-description-2")}<br />
                {localize("landing-services-section-description-3")}
            </Text>
        </div>
        <div
            className={classes.cardsContainer}
        >
            {
                OUR_SERVICES.map((item, index) => {
                    return <ServiceCard
                        key={`service-card-${index}`}
                        classes={classes}
                        index={index}
                        item={item}
                    />;
                })
            }
        </div>
    </Container>;
};
export default Services;
