import {
    Menu
} from "../../components/header/props";

const MAIN_MENU: Array<Menu> = [
    {
        localeKey: "home-page-title",
        path: "/#"
    },
    {
        localeKey: "about-us-title",
        path: "/#about"
    },
    {
        localeKey: "services",
        path: "/#services"
    },
    {
        localeKey: "our-team",
        path: "/#team"
    },
    {
        localeKey: "community",
        path: "/#community"
    },
    {
        localeKey: "contact",
        path: "/#contact"
    }
];
export default MAIN_MENU;
