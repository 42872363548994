import useStyles from "./style";

const Map = () => {
    const classes = useStyles();

    return <div
        id="map"
        className={classes.container}
    >
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.9229728508885!2d32.51161531532339!3d37.978926879722714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08e8efe6713df%3A0x3480614f0684fe3e!2zTsSwQkdBVMKu!5e0!3m2!1str!2str!4v1661171843124!5m2!1str!2str"
            width="100%"
            height="300"
            loading="lazy"
            className={classes.iframe}
        ></iframe>
    </div>;
};
export default Map;
