import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const MediumIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    style,
    onClick,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        className={className}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="M8.851 11.548a.612.612 0 0 0-.196-.514l-1.458-1.77V9h4.525l3.498 7.73L18.295 9h4.315v.265l-1.247 1.202a.37.37 0 0 0-.138.353v8.846a.368.368 0 0 0 .138.353l1.218 1.204v.265H16.46v-.265l1.261-1.233c.124-.125.124-.161.124-.352v-7.15l-3.506 8.97h-.473l-4.082-8.97V18.5a.836.836 0 0 0 .227.69l1.64 2.005v.263H7v-.263l1.639-2.005a.804.804 0 0 0 .212-.69v-6.952Z"
            fill={color}
        />
    </svg>;
};
export default MediumIcon;
