import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        userSelect: "none",
        display: "flex",
        height: "auto",
        width: "100%"
    },
    titleContainer: {
        flexDirection: "column",
        display: "flex"
    },
    title: {
        borderBottomStyle: "solid",
        alignSelf: "baseline",
        borderBottomWidth: 1
    },
    contentContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 1200px)": {
            flexDirection: "column"
        }
    },
    formContainer: {
        width: "100%"
    },
    infoContainer: {
        flexDirection: "column",
        minWidth: "20%",
        display: "flex",
        "@media screen and (max-width: 1200px)": {
            minWidth: "auto",
            width: "100%"
        }
    },
    phoneNumber: {
        alignItems: "center",
        display: "flex",
        "&:hover": {
            cursor: "pointer"
        }
    },
    addressContainer: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    address: {
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    website: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    nibgatbuild: {
        "@media screen and (max-width: 1200px)": {
            width: "100%"
        }
    },
    responseContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    responseSendedIcon: {
    }
}, {
    name: "Project-Landing-Page-Contact-Section"
});
export default useStyles;
