import {
    useEffect,
    useState
} from "react";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Container,
    Button,
    Text
} from "ncore-web";
import useStyles from "./style";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    DiscordIcon
} from "../../../../assets/svgr";
import ICommunitySectionProps from "./props";
import axios from "axios";
import {
    SERVER_ADDRESS 
} from "../../../../constants/urls";

const Community = ({
    dimensions
}: ICommunitySectionProps) => {
    const {
        localize
    } = useNCoreLocalization();

    const {
        activeTheme,
        spaces,
        colors
    } = useNCoreTheme();

    const classes = useStyles();

    const [discordMemberCount, setDiscordMemberCount] = useState(null);

    useEffect(() => {
        getDiscordMemberCount();
    }, []);

    const getDiscordMemberCount = () => {
        axios({
            method: "GET",
            url: `${SERVER_ADDRESS}/getDiscordMemberCount`
        })
            .then((res) => {
                setDiscordMemberCount(res.data.payload.memberCount);
            })
            .catch((err) => {
                if(err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message);
                } else {
                    alert(err.message);
                }
            });
    };

    return <Container
        id="community"
        contentClassName={classes.container}
        contentContainerStyle={{
            paddingRight: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4,
            paddingLeft: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4
        }}
    >
        <div
            className={classes.titleContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content * 10
            }}
        >
            <Text
                className={classes.title}
                variant="header2"
                style={{
                    borderBottomColor: colors.primary,
                    paddingRight: spaces.inline * 8,
                    paddingBottom: spaces.inline
                }}
            >
                {localize("landing-community-section-title")}
            </Text>
        </div>
        <div
            className={classes.contentContainer}
        >
            <img
                className={classes.communityLogo}
                src="/assets/images/community/anim.gif"
            />
            <div
                className={classes.content}
                style={{
                    marginLeft: dimensions.width < 1200 ? 0 : spaces.content * 4
                }}
            >
                <Text>
                    {localize("landing-community-section-description-1")}
                    <br/>
                    <br/>
                    {localize("landing-community-section-description-2")}
                    <br/>
                    <br/>
                    {localize("landing-community-section-description-3")}
                </Text>
                <div
                    style={{
                        marginTop: spaces.content * 3
                    }}
                >
                    <Text
                        color="body"
                    >
                        <Text
                            color={activeTheme === "dark" ? "discordLight" : "discord"}
                            variant="header5"
                        >{discordMemberCount !== null ? discordMemberCount : "90+"}</Text> {localize("developer")}
                    </Text>
                    <Button
                        title={localize("join-us")}
                        color="discord"
                        spreadBehaviour={dimensions.width < 1200 ? "stretch" : "baseline"}
                        textColor={activeTheme === "dark" ? "body" : "constrastBody"}
                        icon={() => <DiscordIcon
                            color={activeTheme === "dark" ? colors.body : colors.constrastBody}
                            size={1.25}
                        />}
                        onClick={() => {
                            window.open("https://discord.gg/fMgVPZknuM", "_blank");
                        }}
                    />
                </div>
            </div>
        </div>
    </Container>;
};
export default Community;
