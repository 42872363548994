const GLOBAL_STATE_INITIAL_STORE = {
    landingNav: [
        {
            location: 0,
            key: "#\\/",
            path: "/#",
            title: "slogan"
        },
        {
            location: 0,
            key: "#about",
            path: "/#about",
            title: "about-us-title"
        },
        {
            location: 0,
            key: "#services",
            path: "/#services",
            title: "services"
        },
        {
            location: 0,
            key: "#team",
            path: "/#team",
            title: "our-team"
        },
        {
            location: 0,
            key: "#community",
            path: "/#community",
            title: "community"
        },
        {
            location: 0,
            key: "#contact",
            path: "/#contact",
            title: "contact"
        }
    ],
    currentPageHash: "/#"
};
export default GLOBAL_STATE_INITIAL_STORE;
