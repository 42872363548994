import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const FacebookIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    style,
    onClick,
    className,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        className={className}
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="m18.812 15.78.434-2.825h-2.71v-1.833c0-.773.378-1.526 1.592-1.526h1.232V7.19S18.242 7 17.173 7c-2.232 0-3.691 1.353-3.691 3.803v2.153H11v2.825h2.482v6.829h3.053v-6.83h2.277Z"
            fill={color}
        />
    </svg>;
};
export default FacebookIcon;
