import {
    useLayoutEffect,
    useEffect,
    useState,
    useRef
} from "react";
import {
    useGlobalState 
} from "../../context";
import {
    goTo, replaceMeta
} from "../../utils";
import useStyles from "./style";
import $ from "jquery";
import About from "./views/aboutSection";
import FirstSection from "./views/firstSection/indtex";
import Services from "./views/servicesSection";
import {
    useNCoreLocalization 
} from "ncore-web";
import OurTeam from "./views/ourTeam";
import Community from "./views/communitySection";
import Contact from "./views/contactSection";
import Map from "./views/map";
import Footer from "../../components/footer";

const Landing = () => {
    const {
        activeLocale,
        localize
    } = useNCoreLocalization();
    const [globalState, setGlobalState] = useGlobalState();

    const classes = useStyles();

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const [scrollData, setScrollData] = useState({
        scrollHeight: document.documentElement.scrollHeight,
        offsetHeight: document.documentElement.offsetHeight,
        scrollTop: document.documentElement.scrollTop
    });

    useEffect(() => {
        window.addEventListener("scroll", scrolled, true);
        window.addEventListener("resize", resized);
        // @ts-ignore
        const hash = window.location.hash;
        setGlobalState({
            currentPageHash: hash === "" ? "#" : hash
        });
        if(!(hash === "" || hash === "/" || hash === "/#")) {
            goTo(hash);
        }

        return () => {
            window.removeEventListener("scroll", scrolled);
            window.removeEventListener("resize", resized);
        };
    }, []);

    useLayoutEffect(() => {
        const scrollHeight = document.documentElement.scrollHeight;
        const offsetHeight = document.documentElement.offsetHeight;
        let _landingNav = JSON.parse(JSON.stringify(globalState.landingNav));

        for(let i = 0; i < _landingNav.length; i++) {
            const elementTop = $(_landingNav[i].key).position().top;
            const percent = (elementTop * 100) / scrollHeight;
            const scrollTotal = scrollHeight - offsetHeight;
            const scrollPosition = (scrollTotal * percent) / 100;

            _landingNav[i] = {
                ..._landingNav[i],
                location: scrollPosition
            };
        }

        setGlobalState({
            landingNav: _landingNav
        });
    }, [dimensions]);

    useEffect(() => {
        let scrollRangeIndex = 0;

        for(let i = 0; i < globalState.landingNav.length; i++) {
            if(globalState.landingNav[1].location === 0) {
                break;
            }

            if(scrollData.scrollTop >= globalState.landingNav[i].location && i + 1 === globalState.landingNav.length) {
                scrollRangeIndex = i;
                break;
            }

            if(
                scrollData.scrollTop >= globalState.landingNav[i].location && 
                scrollData.scrollTop < globalState.landingNav[i + 1].location
            ) {
                scrollRangeIndex = i;
                break;
            }
        }

        const key = globalState.landingNav[scrollRangeIndex].path.replace("/", "");
        if(globalState.currentPageHash !== key) {
            window.history.pushState("", "", globalState.landingNav[scrollRangeIndex].path);
        }
        // @ts-ignore
        document.title = `NİBGAT® | ${localize(globalState.landingNav[scrollRangeIndex].title)}`;
        replaceMeta({
            image: "https://www.nibgat.com/assets/images/nibgatbuild.png",
            // @ts-ignore
            title: `NİBGAT® | ${localize(globalState.landingNav[scrollRangeIndex].title)}`,
            type: "website",
            url: `https://www.nibgat.com${globalState.landingNav[scrollRangeIndex].path}`,
            "site_name": "NİBGAT®",
            description: "Rise of the Developers",
            locale: activeLocale
        });

        setGlobalState({
            currentPageHash: key === "" ? "#" : key
        });
    }, [scrollData, globalState.landingNav]);

    const scrolled = (event: Event) => {
        // @ts-ignore
        const scrollHeight = event.target.scrollingElement.scrollHeight;
        // @ts-ignore
        const scrollTop = event.target.scrollingElement.scrollTop;
        // @ts-ignore
        const offsetHeight = event.target.scrollingElement.offsetHeight;

        setScrollData({
            scrollHeight,
            offsetHeight,
            scrollTop
        });
    };

    const resized = (event: Event) => {
        // @ts-ignore
        const height = event.target.window.innerHeight;
        // @ts-ignore
        const width = event.target.window.innerWidth;

        setDimensions({
            height: height,
            width: width
        });
    };

    return <div
        className={classes.container}
    >
        <FirstSection
            onMouseWheel={() => {
                goTo("/#about");
            }}
            dimensions={dimensions}
        />
        <About
            scrollData={scrollData}
            dimensions={dimensions}
        />
        <Services
            scrollData={scrollData}
            dimensions={dimensions}
        />
        <OurTeam
            scrollData={scrollData}
            dimensions={dimensions}
        />
        <Community
            dimensions={dimensions}
        />
        <Contact
            dimensions={dimensions}
        />
        <Map/>
        <Footer/>
    </div>;
};
export default Landing;
