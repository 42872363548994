import {
    useEffect
} from "react";
import useStyles from "./style";
import draw from "./motor";
import {
    useNCoreTheme 
} from "ncore-web";

const WelcomeBG = () => {
    const {
        colors
    } = useNCoreTheme();

    const classes = useStyles();

    useEffect(() => {
        draw();
    }, []);

    return <canvas
        className={classes.container}
        id="welcomeBGCanvas"
        style={{
            backgroundColor: colors.layer1,
            height: "100%",
            width: "100%"
        }}
    >
        Canvas not supported.
    </canvas>;
};
export default WelcomeBG;
