import {
    CSSProperties
} from "react";
import {
    INCoreIconProps
} from "ncore-web";

const MenuIcon = ({
    size = 22,
    className,
    onClick,
    color = "#000",
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    const width = (315 * size) / 225;

    return <svg
        width={width}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        {...props}
    >
        <path
            d="M0 225h315v-45H0v45ZM0 0v45h315V0H0Zm0 135h315V90H0v45Z"
            fill={color}
            style={{
                transform: `scale(${size / 225})`,
            }}
        />
    </svg>;
};
export default MenuIcon;
