import {
    Fragment,
    useState
} from "react";
import {
    useNCoreLocalization,
    useNCoreTheme,
    TextInput,
    Container,
    Button,
    Text
} from "ncore-web";
import {
    LocationIcon,
    SendedIcon,
    PhoneIcon,
    MailIcon
} from "../../../../assets/svgr";
import useStyles from "./style";
import axios from "axios";
import {
    SERVER_ADDRESS 
} from "../../../../constants/urls";
import IContactSectionProps from "./props";

const Contact = ({
    dimensions
}: IContactSectionProps) => {
    const {
        radiuses,
        spaces,
        colors
    } = useNCoreTheme();
    const {
        activeLocale,
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    const [nameSurname, setNameSurname] = useState("");
    const [mail, setMail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [contactLoading, setContactLoading] = useState(false);
    const [contactFormResponse, setContactFormResponse] = useState<{
        isHaveResponse: boolean;
        payload?: {
            message: string;
            code: number;
        } | null
    }>({
        isHaveResponse: false,
        payload: null
    });

    const sendContact = () => {
        setContactLoading(true);
        axios({
            method: "POST",
            url: `${SERVER_ADDRESS}/contact`,
            data: {
                nameSurname: nameSurname,
                mail: mail,
                subject: subject,
                message: message,
                lang: activeLocale
            }
        })
            .then((res) => {
                setContactFormResponse({
                    isHaveResponse: true,
                    payload: res.data
                });
                setContactLoading(false);
                setNameSurname("");
                setMail("");
                setSubject("");
                setMessage("");
            })
            .catch((err) => {
                if(err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message);
                } else {
                    alert(err.message);
                }
                setContactLoading(false);
            });
    };

    return <Container
        id="contact"
        contentClassName={classes.container}
        contentContainerStyle={{
            paddingRight: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4,
            paddingLeft: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4
        }}
    >
        <div
            className={classes.titleContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content * 9
            }}
        >
            <Text
                className={classes.title}
                variant="header2"
                style={{
                    borderBottomColor: colors.primary,
                    paddingRight: spaces.inline * 8,
                    paddingBottom: spaces.inline
                }}
            >
                {localize("landing-contact-section-title")}
            </Text>
        </div>
        <div
            className={classes.contentContainer}
        >
            <div
                className={classes.infoContainer}
                style={{
                    paddingRight: dimensions.width < 1200 ? 0 : spaces.container
                }}
            >
                <img
                    src="/assets/images/nibgatbuild.png"
                    className={classes.nibgatbuild}
                    style={{
                        marginBottom: spaces.content * 2,
                        borderRadius: radiuses.half
                    }}
                />
                <Text
                    color="body"
                    variant="header4"
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    NİBGAT®
                </Text>
                <span
                    onClick={() => {
                        window.open("tel:0850-308-87-72");
                    }}
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    <Text
                        className={classes.phoneNumber}
                        color="info"
                    >
                        <PhoneIcon
                            color={colors.info}
                            style={{
                                marginRight: spaces.inline
                            }}
                        />
                        0850 308 87 72
                    </Text>
                </span>
                <span
                    className={classes.addressContainer}
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    <LocationIcon
                        color={colors.body}
                        style={{
                            marginRight: spaces.inline
                        }}
                    />
                    <Text
                        className={classes.address}
                    >
                        {localize("contact-address")}
                    </Text>
                </span>
                <span
                    onClick={() => {
                        window.open("mailto:contact@nibgat.com", "_blank");
                    }}
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    <MailIcon
                        color={colors.primary}
                        size={1.25}
                        style={{
                            marginRight: spaces.inline * 2,
                            marginLeft: spaces.inline / 2
                        }}
                    />
                    <Text
                        className={classes.website}
                        color="primary"
                    >
                        {localize("contact-mail")}
                    </Text>
                </span>
            </div>
            <div
                className={classes.formContainer}
                style={{
                    justifyContent: contactFormResponse.isHaveResponse ? "center" : undefined,
                    alignItems: contactFormResponse.isHaveResponse ? "center": undefined,
                    display: contactFormResponse.isHaveResponse ? "flex": undefined,
                    height: contactFormResponse.isHaveResponse ? "100%": undefined
                }}
            >
                {
                    contactFormResponse.isHaveResponse ?
                        <div
                            className={classes.responseContainer}
                        >
                            <SendedIcon
                                color={contactFormResponse?.payload?.code === 200 ? colors.success : colors.accent}
                                size={50}
                                className={classes.responseSendedIcon}
                                style={{
                                    marginBottom: spaces.content
                                }}
                            />
                            <Text
                                color={contactFormResponse?.payload?.code === 200 ? "success" : "accent"}
                            >
                                {contactFormResponse?.payload?.message}
                            </Text>
                        </div>
                        :
                        <Fragment>
                            <TextInput
                                id="nameSurname"
                                title={localize("name-surname")}
                                placeholder={localize("please-enter-name-surname")}
                                onChangeText={(e) => {
                                    setNameSurname(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 2
                                }}
                            />
                            <TextInput
                                id="eMail"
                                title={localize("e-mail")}
                                placeholder={localize("please-enter-e-mail")}
                                onChangeText={(e) => {
                                    setMail(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 2
                                }}
                            />
                            <TextInput
                                id="subject"
                                title={localize("subject")}
                                placeholder={localize("please-enter-subject")}
                                onChangeText={(e) => {
                                    setSubject(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 2
                                }}
                            />
                            <TextInput
                                id="message"
                                title={localize("message")}
                                placeholder={localize("please-enter-message")}
                                onChangeText={(e) => {
                                    setMessage(e);
                                }}
                                multiline={true}
                                style={{
                                    marginBottom: spaces.content * 2
                                }}
                            />
                            <Button
                                spreadBehaviour="stretch"
                                title={localize("send")}
                                loading={contactLoading}
                                onClick={() => {
                                    sendContact();
                                }}
                            />
                        </Fragment>
                }
            </div>
        </div>
    </Container>;
};
export default Contact;
