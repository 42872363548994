import {
    INCoreIconProps
} from "ncore-web";

const SunIcon = ({
    size = 1,
    ...props
}: INCoreIconProps) => {
    return <svg
        width={19}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`
        }}
        {...props}
    >
        <path
            d="M9.375 18.75a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75Z"
            fill="#ED4C5C"
        />
        <path
            d="m12.281 11.563.031-1.688-1.687-.5 1.688-.5-.032-1.688 1.031 1.344 1.688-.5-1.031 1.344L15 10.719l-1.688-.5-1.03 1.344Z"
            fill="#fff"
        />
        <path
            d="M9.75 13.125c-2.063 0-3.719-1.688-3.719-3.75 0-2.063 1.657-3.75 3.719-3.75.781 0 1.5.25 2.125.656a4.63 4.63 0 0 0-3.5-1.593c-2.563 0-4.625 2.093-4.625 4.687 0 2.594 2.063 4.688 4.625 4.688a4.63 4.63 0 0 0 3.5-1.594 3.724 3.724 0 0 1-2.125.656Z"
            fill="#fff"
        />
    </svg>;
};
export default SunIcon;
