import {
    useNCoreLocalization,
    useNCoreTheme,
    Text
} from "ncore-web";
import IServiceCard from "./props";

const ServiceCard = ({
    classes,
    index,
    item
}: IServiceCard) => {
    const {
        radiuses,
        spaces,
        colors
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    return <div
        id={`service-card-${index}`}
        className={classes.card}
        style={{
            marginBottom: spaces.content * 4,
            backgroundColor: colors.panel,
            borderRadius: radiuses.half
        }}
    >
        <div
            className={classes.cardIconContainer}
            style={{
                borderTopRightRadius: radiuses.half,
                borderTopLeftRadius: radiuses.half
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${item.cover})`,
                    borderTopRightRadius: radiuses.half,
                    borderTopLeftRadius: radiuses.half
                }}
            ></div>
        </div>
        <div
            className={classes.textsContainer}
            style={{
                padding: spaces.container
            }}
        >
            <Text
                variant="header7"
                color="primary"
                style={{
                    marginBottom: spaces.inline * 2
                }}
            >
                {localize(item.title)}
            </Text>
            <Text>
                {localize(item.content)}
            </Text>
        </div>
    </div>;
};
export default ServiceCard;
