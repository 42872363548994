import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        userSelect: "none",
        width: "100%"
    },
    iframe: {
        border: "none"
    }
}, {
    name: "Project-Landing-Page-Map"
});
export default useStyles;
