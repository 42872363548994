import {
    CSSProperties
} from "react";
import {
    INCoreIconProps
} from "ncore-web";

const LocationIcon = ({
    size = 1,
    color = "#000",
    style,
    ...props
}: INCoreIconProps & {
    style?: CSSProperties
}) => {
    return <svg
        width={18}
        height={21}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        {...props}
    >
        <path
            d="M8 0C3.582 0 0 3.544 0 7.916c0 1.462.29 2.972 1.12 4.06L8 21l6.88-9.023C15.636 10.988 16 9.24 16 7.916 16 3.544 12.418 0 8 0Zm0 4.585c1.86 0 3.367 1.491 3.367 3.331 0 1.84-1.508 3.331-3.367 3.331S4.633 9.756 4.633 7.916c0-1.84 1.508-3.331 3.367-3.331Z"
            fill={color}
        />
    </svg>;
};
export default LocationIcon;
