import useStyles from "./style";
import {
    useNCoreTheme,
    Switcher
} from "ncore-web";
import {
    MoonIcon,
    SunIcon
} from "../../assets/svgr";

const ThemeSwitcher = () => {
    const {
        activeTheme,
        switchTheme,
        spaces,
        colors
    } = useNCoreTheme();

    const classes = useStyles();

    return <div
        className={classes.container}
        style={{
            marginRight: spaces.content
        }}
    >
        <SunIcon
            color={colors.warning}
            size={1.5}
        />
        <Switcher
            isActive={activeTheme === "dark"}
            onChange={() => {
                const newTheme = activeTheme === "dark" ? "light" : "dark";
                switchTheme(newTheme);
                window.localStorage.setItem("theme", newTheme);
            }}
            style={{
                marginRight: spaces.content,
                marginLeft: spaces.content
            }}
        />
        <MoonIcon
            color={colors.body}
            size={1.5}
        />
    </div>;
};
export default ThemeSwitcher;
