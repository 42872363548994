import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    "@global": {
        "@keyframes mousescroll": {
            "0%": {
                transform: "translateY(0px)"
            },
            "25%": {
                transform: "translateY(20px)"
            },
            "95%": {
                transform: "translateY(30px)"
            },
            "100%": {
                transform: "translateY(0px)"
            }
        }
    },
    container: {
        animation: "mousescroll 2s linear infinite",
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        position: "absolute",
        borderRadius: 20,
        display: "flex",
        borderWidth: 2,
        bottom: -200,
        height: 40,
        width: 24,
        "&:hover": {
            borderColor: "#00c2a9 !important",
            cursor: "pointer",
            opacity: 0.75
        },
        "&:hover $wheel": {
            backgroundColor: "#00c2a9 !important"
        },
        "@media screen and (max-height: 750px)": {
            bottom: -100
        }
    },
    wheel: {
        transform: "translateY(-8px)",
        borderRadius: 4,
        height: 8,
        width: 4
    }
}, {
    name: "Project-Mouse-Scroll-Indicator"
});
export default useStyles;
