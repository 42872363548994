import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Text
} from "ncore-web";
import {
    Menu as MenuType
} from "../header/props";
import {
    goTo 
} from "../../utils";
import {
    useGlobalState 
} from "../../context";

type IMenuProps = {
    data?: Array<MenuType>;
};

const Menu = ({
    data
}: IMenuProps) => {
    const {
        spaces
    } = useNCoreTheme();
    const [globalState, setGlobalState] = useGlobalState();

    const {
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    return <div
        className={classes.container}
    >
        {
            data?.map((item, index) => {
                return <div
                    key={`ncore-menu-item-${index}`}
                    className={classes.item}
                    onClick={() => {
                        goTo(item.path);
                    }}
                    style={{
                        padding: `${spaces.content}px ${spaces.content * 2}px`
                    }}
                >
                    <Text
                        variant="header7"
                        color={globalState.currentPageHash === item.path.replace("/", "") ? "primary" : "body"}
                    >
                        {localize(item.localeKey)}
                    </Text>
                </div>;
            })
        }
    </div>;
};
export default Menu;
