import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    }
}, {
    name: "Project-Language-Switcher"
});
export default useStyles;
