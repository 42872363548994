import {
    useNCoreLocalization,
    useNCoreTheme,
    Button,
    Text
} from "ncore-web";
import {
    useGlobalState 
} from "../../context";
import {
    goTo 
} from "../../utils";
import LanguageSwitcher from "../languageSwitcher";
import ThemeSwitcher from "../themeSwitcher";
import IMobileMenu from "./props";
import useStyles from "./style";

const MobileMenu = ({
    setIsActiveMenu,
    isActiveMenu,
    dimensions,
    data
}: IMobileMenu) => {
    const [globalState] = useGlobalState();
    const {
        radiuses,
        colors,
        spaces
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    return <div
        className={classes.container}
        style={{
            transform: `translateX(${isActiveMenu ? 0 : dimensions.width}px)`,
            backgroundColor: colors.modalBackground
        }}
        onClick={(e) => {
            setIsActiveMenu(false);
        }}
    >
        <div
            className={classes.content}
            style={{
                backgroundColor: colors.layer1
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div
                className={classes.header}
                style={{
                    borderBottomColor: colors.seperator,
                    padding: spaces.container
                }}
            >
                <Text
                    color="primary"
                    variant="header5"
                >
                    {localize("menu")}
                </Text>
                <div
                    onClick={() => {
                        setIsActiveMenu(false);
                    }}
                    className={classes.closeButton}
                    style={{
                        padding: `${spaces.content}px ${spaces.content * 2}px`,
                        backgroundColor: colors.layer3,
                        borderRadius: radiuses.half
                    }}
                >
                    <Text
                        color="body"
                    >
                        X
                    </Text>
                </div>
            </div>
            <div
                className={classes.buttonsContainer}
            >
                <div
                    className={classes.buttons}
                    style={{
                        paddingTop: spaces.content
                    }}
                >
                    {
                        data?.map((item, index) => {
                            return <div
                                key={`ncore-menu-item-${index}`}
                                className={classes.item}
                                onClick={() => {
                                    goTo(item.path);
                                    setIsActiveMenu(false);
                                }}
                                style={{
                                    padding: `${spaces.content * 1.5}px ${spaces.content * 2}px`
                                }}
                            >
                                <Text
                                    variant="header7"
                                    color={globalState.currentPageHash === item.path.replace("/", "") ? "primary" : "body"}
                                >
                                    {localize(item.localeKey)}
                                </Text>
                            </div>;
                        })
                    }
                </div>
                <div
                    className={classes.toolsContainer}
                    style={{
                        padding: spaces.container
                    }}
                >
                    <ThemeSwitcher/>
                    <LanguageSwitcher/>
                </div>
            </div>
        </div>
    </div>;
};
export default MobileMenu;
