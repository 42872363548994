import {
    useNCoreLocalization,
    useNCoreTheme,
    Text
} from "ncore-web";
import useStyles from "./style";

const Footer = () => {
    const {
        colors,
        spaces
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    return <div
        id="footer"
        className={classes.container}
    >
        <div
            className={classes.footerTable}
            style={{
                backgroundColor: colors.layer1
            }}
        >
            <div
                className={classes.footerTableCell}
                style={{
                    padding: spaces.container
                }}
            >
                <div
                    className={classes.footerContainer}
                >
                    <Text>
                        {localize("copyright")} © {new Date().getFullYear()} NİBGAT® - {localize("all-rights-reserved")}.
                    </Text>
                    <Text
                        color="primary"
                        style={{
                            marginRight: spaces.content
                        }}
                    >
                        v1.0
                    </Text>
                </div>
            </div>
        </div>
    </div>;
};
export default Footer;
