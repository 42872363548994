import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const InstagramIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    style,
    onClick,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        className={className}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="M14.805 12.204a2.609 2.609 0 0 0-2.603 2.603 2.609 2.609 0 0 0 2.603 2.604 2.609 2.609 0 0 0 2.604-2.604 2.609 2.609 0 0 0-2.604-2.603Zm7.809 2.603c0-1.078.01-2.146-.05-3.222-.061-1.25-.347-2.36-1.26-3.274-.917-.916-2.024-1.2-3.274-1.26-1.078-.06-2.147-.05-3.223-.05-1.078 0-2.146-.01-3.222.05-1.25.06-2.36.346-3.274 1.26-.916.916-1.2 2.024-1.26 3.274-.06 1.078-.05 2.146-.05 3.222s-.01 2.147.05 3.223c.06 1.25.346 2.36 1.26 3.273.916.916 2.024 1.2 3.274 1.26 1.078.06 2.146.05 3.222.05 1.078 0 2.147.01 3.223-.05 1.25-.06 2.36-.346 3.273-1.26.916-.916 1.2-2.023 1.26-3.273.063-1.076.05-2.145.05-3.223Zm-7.809 4.006a4 4 0 0 1-4.006-4.006 4 4 0 0 1 4.006-4.006 4 4 0 0 1 4.006 4.006 4 4 0 0 1-4.006 4.006Zm4.17-7.24a.934.934 0 1 1 0-1.871.934.934 0 1 1 0 1.87Z"
            fill={color}
        />
    </svg>;
};
export default InstagramIcon;
