import {
    useEffect,
    useRef
} from "react";
import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Button,
    Text
} from "ncore-web";
import WelcomeBG from "../../../../components/welcomeBG";
import MouseScrollIndicator from "../../../../components/mouseScrollIndicator";
import IFirstSectionProps from "./props";
import $ from "jquery";
import {
    goTo 
} from "../../../../utils";

const FirstSection = ({
    onMouseWheel,
    dimensions
}: IFirstSectionProps) => {
    const {
        localize
    } = useNCoreLocalization();
    const {
        activeTheme,
        spaces
    } = useNCoreTheme();

    const classes = useStyles();
    const titleContainerRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        $(titleContainerRef.current).ready(() => {
            // @ts-ignore
            $(titleContainerRef.current).addClass("loadWithScale");
        });
    }, []);

    return <div
        id="/"
        className={classes.table}
    >
        <div
            className={classes.tableCell}
        >
            <div
                className={classes.content}
            >
                <WelcomeBG/>
                <div
                    ref={titleContainerRef}
                    className={classes.contentContainer}
                >
                    <Text
                        className={classes.title}
                        variant={dimensions.width < 1200 ? "header2" : "header1"}
                        color={activeTheme === "dark" ? "primary" : "body"}
                        style={{
                            marginBottom: spaces.content * 4
                        }}
                    >
                        {localize("landing-welcome-section-title")}
                    </Text>
                    <Text
                        className={classes.slogan}
                        variant={dimensions.width < 1200 ? "header4" : "header3"}
                        color="info"
                        style={{
                            marginBottom: spaces.content * 4
                        }}
                    >
                        {localize("landing-welcome-section-slogan")}
                    </Text>
                    <Button
                        className={classes.getOffer}
                        title={localize("get-offer")}
                        variant={activeTheme === "light" ? "filled" : "outline"}
                        spreadBehaviour="free"
                        onClick={() => {
                            goTo("/get-offer");
                        }}
                    />
                    <MouseScrollIndicator
                        onClick={() => {
                            if(onMouseWheel) onMouseWheel();
                        }}
                    />
                </div>
            </div>
        </div>
    </div>;
};
export default FirstSection;
