import {
    useEffect,
    Fragment,
    useState
} from "react";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Container,
    TextInput,
    Button,
    Text
} from "ncore-web";
import useStyles from "./style";
import axios from "axios";
import {
    SERVER_ADDRESS 
} from "../../constants/urls";
import {
    SendedIcon 
} from "../../assets/svgr";

const GetOffer = () => {
    const {
        spaces,
        colors
    } = useNCoreTheme();
    const {
        activeLocale,
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    const [activeInputIndex, setActiveInputIndex] = useState(-1);
    const [projectName, setProjectName] = useState("");
    const [nameSurname, setNameSurname] = useState("");
    const [mail, setMail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [details, setDetails] = useState("");

    const [sendOfferRequestLoading, setSendOfferRequestLoading] = useState(false);
    const [offerRequestFormResponse, setOfferRequestFormResponse] = useState<{
        isHaveResponse: boolean;
        payload?: {
            message: string;
            code: number;
        } | null
    }>({
        isHaveResponse: false,
        payload: null
    });

    useEffect(() => {
        document.title = `NİBGAT® | ${localize("get-offer")}`;
    }, []);

    useEffect(() => {
        if(projectName && projectName.length && activeInputIndex < 0) {
            setActiveInputIndex(activeInputIndex + 2);
        }
    }, [projectName]);

    useEffect(() => {
        if(nameSurname && nameSurname.length && activeInputIndex < 2) {
            setActiveInputIndex(activeInputIndex + 1);
        }
    }, [nameSurname]);

    useEffect(() => {
        if(mail && mail.length && activeInputIndex < 3) {
            setActiveInputIndex(activeInputIndex + 1);
        }
    }, [mail]);

    useEffect(() => {
        if(phoneNumber && phoneNumber.length && activeInputIndex < 4) {
            setActiveInputIndex(activeInputIndex + 1);
        }
    }, [phoneNumber]);

    useEffect(() => {
        if(details && details.length && activeInputIndex < 5) {
            setActiveInputIndex(activeInputIndex + 1);
        }
    }, [details]);

    const sendOfferRequest = () => {
        setSendOfferRequestLoading(true);
        axios({
            method: "POST",
            url: `${SERVER_ADDRESS}/get-offer`,
            data: {
                nameSurname: nameSurname,
                mail: mail,
                phoneNumber: phoneNumber,
                projectName: projectName,
                projectDetails: details,
                lang: activeLocale
            }
        })
            .then((res) => {
                setOfferRequestFormResponse({
                    isHaveResponse: true,
                    payload: res.data
                });
                setSendOfferRequestLoading(false);
                setNameSurname("");
                setMail("");
                setPhoneNumber("");
                setProjectName("");
                setDetails("");
            })
            .catch((err) => {
                if(err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message);
                } else {
                    alert(err.message);
                }
                setSendOfferRequestLoading(false);
            });
    };

    return <Container
        contentClassName={classes.container}
        contentContainerStyle={{
            paddingTop: spaces.container * 8
        }}
        contentStyle={{
            justifyContent: offerRequestFormResponse.isHaveResponse ? "center" : undefined,
            alignItems: offerRequestFormResponse.isHaveResponse ? "center": undefined
        }}
    >
        {
            offerRequestFormResponse.isHaveResponse ?
                <div
                    className={classes.responseContainer}
                >
                    <SendedIcon
                        color={offerRequestFormResponse?.payload?.code === 200 ? colors.success : colors.accent}
                        size={50}
                        className={classes.responseSendedIcon}
                        style={{
                            marginBottom: spaces.content
                        }}
                    />
                    <Text
                        color={offerRequestFormResponse?.payload?.code === 200 ? "success" : "accent"}
                    >
                        {offerRequestFormResponse?.payload?.message}
                    </Text>
                </div>
                :
                <Fragment>
                    <Text
                        color="primary"
                        variant="header3"
                        className={classes.title}
                        style={{
                            marginBottom: spaces.content * 2
                        }}
                    >
                        {localize("get-offer-page-title")}
                    </Text>
                    <Text
                        color="body"
                        variant="header7"
                        className={classes.subTitle}
                        style={{
                            marginBottom: spaces.content * 6
                        }}
                    >
                        {localize("get-offer-page-sub-title")}
                    </Text>
                    {
                        activeInputIndex > -2 ?
                            <TextInput
                                id="projectName"
                                title={localize("project-name")}
                                placeholder={localize("please-enter-project-name")}
                                className={classes.projectName}
                                spreadBehaviour="stretch"
                                onChangeText={(e) => {
                                    setProjectName(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 4
                                }}
                            />
                            :
                            null
                    }
                    {
                        activeInputIndex > 0 ?
                            <TextInput
                                id="nameSurname"
                                title={localize("name-surname")}
                                placeholder={localize("please-enter-name-surname")}
                                className={classes.nameSurname}
                                spreadBehaviour="stretch"
                                onChangeText={(e) => {
                                    setNameSurname(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 4
                                }}
                            />
                            :
                            null
                    }
                    {
                        activeInputIndex > 1 ?
                            <TextInput
                                id="mail"
                                title={localize("e-mail")}
                                placeholder={localize("please-enter-e-mail")}
                                className={classes.mail}
                                spreadBehaviour="stretch"
                                onChangeText={(e) => {
                                    setMail(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 4
                                }}
                            />
                            :
                            null
                    }
                    {
                        activeInputIndex > 2 ?
                            <TextInput
                                id="phone"
                                title={localize("phone-number")}
                                placeholder={localize("please-enter-phone-number")}
                                className={classes.phone}
                                spreadBehaviour="stretch"
                                onChangeText={(e) => {
                                    setPhoneNumber(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 4
                                }}
                            />
                            :
                            null
                    }
                    {
                        activeInputIndex > 3 ?
                            <TextInput
                                id="details"
                                title={localize("project-details")}
                                placeholder={localize("please-enter-project-details")}
                                className={classes.details}
                                spreadBehaviour="stretch"
                                multiline={true}
                                onChangeText={(e) => {
                                    setDetails(e);
                                }}
                                style={{
                                    marginBottom: spaces.content * 4
                                }}
                            />
                            :
                            null
                    }
                    {
                        activeInputIndex > 4 ?
                            <Button
                                title={localize("send")}
                                spreadBehaviour="stretch"
                                loading={sendOfferRequestLoading}
                                onClick={() => {
                                    sendOfferRequest();
                                }}
                            />
                            :
                            null
                    }
                </Fragment>
        }
    </Container>;
};
export default GetOffer;
