import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const UpworkIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    onClick,
    style,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        onClick={onClick}
        className={className}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="M19.032 11.941a3.531 3.531 0 0 0-3.406 2.87A15.478 15.478 0 0 1 13.852 11h-1.758v4.667a1.664 1.664 0 1 1-3.328 0V11L7 11.039v4.667a3.422 3.422 0 1 0 6.844-.04v-.777c.374.732.803 1.434 1.284 2.1l-1.074 5.141h1.805l.778-3.741c.72.463 1.562.704 2.419.692a3.554 3.554 0 0 0 0-7.109l-.024-.03Zm0 5.312a3.181 3.181 0 0 1-1.983-.777l.179-.708v-.04c.124-.777.513-2.021 1.828-2.021a1.75 1.75 0 0 1 1.765 1.742 1.875 1.875 0 0 1-1.765 1.804h-.024Z"
            fill={color}
        />
    </svg>;
};
export default UpworkIcon;
