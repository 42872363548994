import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        position: "absolute",
        display: "block",
        bottom: 0,
        right: 0,
        left: 0,
        top: 0
    }
});
export default useStyles;
