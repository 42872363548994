import {
    ReplaceMetaType 
} from "../types";

export const getBrowserLanguage = () => {
    let browserLang = window.localStorage.getItem("lang") || navigator.language;
    if(browserLang.length > 2) {
        browserLang = browserLang.slice(0, 2);
    }
    return browserLang;
};

export const getBrowserTheme = (): NCore.ThemeKey | null => {
    const browserTheme = window.localStorage.getItem("theme");
    return browserTheme;
};

export const random = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const goTo = (path: string) => {
    window.location.href = path;
    // window.history.pushState(undefined, path, path);
    window.dispatchEvent(new Event("popstate"));
};

export const replaceMeta = (props: ReplaceMetaType) => {
    const keys = Object.keys(props);
    keys.forEach((item) => {
        // @ts-ignore
        document.getElementById(`og${item}`)?.setAttribute("content", props[item]);
    });
};
