import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        userSelect: "none",
        width: "100%"
    },
    footerTable: {
        display: "table",
        width: "100%"
    },
    footerTableCell: {
        display: "table-cell",
        width: "100%"
    },
    footerContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%"
    }
}, {
    name: "Project-Component-Footer"
});
export default useStyles;
