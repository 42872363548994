import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const NIcon = ({
    size = 1,
    color = "#000",
    style,
    ...props
}: INCoreIconProps & { style?: CSSProperties }) => {
    return <svg
        width={33}
        height={31}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`
        }}
        {...props}
    >
        <path
            d="M9.86 2 2 15.63l7.86 13.63M23.56 2l7.86 13.63-7.86 13.63M9.86 2l13.7 27.26"
            stroke={color}
            strokeOpacity={0.941}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>;
};
export default NIcon;
