import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        height: "100%",
        width: "100%"
    }
}, {
    name: "Project-Landing-Page"
});
export default useStyles;
