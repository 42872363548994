import useStyles from "./style";
import {
    useNCoreTheme 
} from "ncore-web";
import IMouseScrollIndicator from "./props";

const MouseScrollIndicator = ({
    onClick
}: IMouseScrollIndicator) => {
    const {
        colors
    } = useNCoreTheme();

    const classes = useStyles();

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.danger + "10",
            borderColor: colors.danger
        }}
        onClick={() => {
            if(onClick) onClick();
        }}
    >
        <div
            className={classes.wheel}
            style={{
                backgroundColor: colors.danger
            }}
        ></div>
    </div>;
};
export default MouseScrollIndicator;
