import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        userSelect: "none",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    title: {
        textAlign: "center",
        width: "100%"
    },
    subTitle: {
        textAlign: "center",
        width: "100%"
    },
    projectName: {
    },
    nameSurname: {
    },
    mail: {
    },
    phone: {
    },
    details: {
    },
    responseContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    responseSendedIcon: {
    }
}, {
    name: "Project-Get-Offer-Page"
});
export default useStyles;
