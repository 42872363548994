import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        transform: "translateY(150px)",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        userSelect: "none",
        display: "flex",
        width: "28%",
        opacity: 0,
        "&.active": {
            transform: "translateY(0)",
            opacity: 1
        },
        "@media screen and (max-width: 1200px)": {
            width: "100%"
        }
    },
    profileImage: {
        borderRadius: 50,
        height: 100,
        width: 100
    },
    content: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    icon: {
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        }
    },
    socialLinksContainer: {
        "@media screen and (max-width: 1200px)": {
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            display: "flex"
        }
    }
}, {
    name: "Project-Landing-Page-Our-Team-Team-Card"
});
export default useStyles;
