import {
    FC
} from "react";
import {
    INCoreIconProps
} from "ncore-web";
import {
    TurkishIcon,
    EnglishIcon
} from "../../assets/svgr";

export const LANGUAGES = [
    "tr",
    "en"
];

export const LANGUAGE_ICONS: Record<string, FC<INCoreIconProps>> = {
    "tr": ({
        color,
        size
    }: INCoreIconProps) => <TurkishIcon
        size={size}
    />,
    "en": ({
        color,
        size
    }: INCoreIconProps) => <EnglishIcon
        size={size}
    />
};
