import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const LinkedinIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    style,
    onClick,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        className={className}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.798 12.44h2.9v1.443c.417-.83 1.488-1.576 3.096-1.576 3.084 0 3.816 1.653 3.816 4.686v5.617h-3.122v-4.927c0-1.727-.418-2.7-1.48-2.7-1.475 0-2.088 1.049-2.088 2.7v4.927h-3.122V12.44ZM7.445 22.476h3.122v-10.17H7.445v10.17Zm3.57-13.487a1.975 1.975 0 0 1-.589 1.407 2.021 2.021 0 0 1-2.84 0A1.988 1.988 0 0 1 7 8.99c0-.528.21-1.034.588-1.407a2.016 2.016 0 0 1 2.838 0c.377.373.588.879.588 1.407Z"
            fill={color}
        />
    </svg>;
};
export default LinkedinIcon;
