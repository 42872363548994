import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "row",
        display: "flex"
    },
    item: {
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        }
    }
}, {
    name: "Project-Menu"
});
export default useStyles;
