import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    table: {
        display: "table",
        height: "100%",
        width: "100%"
    },
    tableCell: {
        display: "table-cell",
        height: "100%",
        width: "100%"
    },
    content: {
        justifyContent: "center",
        position: "relative",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    contentContainer: {
        justifyContent: "center",
        flexDirection: "column",
        transform: "scale(0)",
        alignItems: "center",
        position: "absolute",
        alignSelf: "center",
        display: "flex",
        "&.loadWithScale": {
            transform: "scale(1)"
        }
    },
    title: {
        textAlign: "center",
        userSelect: "none"
    },
    slogan: {
        textAlign: "center",
        userSelect: "none"
    },
    getOffer: {
        
    }
}, {
    name: "Project-Landing-Page-First-Section-View"
});
export default useStyles;
