import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    "@global": {
        "@keyframes infiniteRotate": {
            from: {
                transform: "rotate(0deg)"
            },
            to: {
                transform: "rotate(-360deg)"
            }
        }
    },
    container: {
        justifyContent: "space-between",
        backdropFilter: "blur(4px)",
        alignItems: "center",
        flexDirection: "row",
        userSelect: "none",
        position: "fixed",
        display: "flex",
        zIndex: 999,
        right: 0,
        left: 0,
        top: 0
    },
    header: {
        justifyContent: "center",
        flexDirection: "row",
        alignSelf: "center",
        display: "flex",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        },
        "&:active": {
            transform: "translateY(2px)"
        }
    },
    rightContainer: {
        flexDirection: "column",
        alignItems: "flex-end",
        display: "flex"
    },
    logo: {
        transform: "scale(0)",
        animation: "infiniteRotate 3s linear infinite",
        "&.loadAnimate": {
            transform: "scale(1) !important"
        }
    },
    titleContainer: {
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        transform: "scale(0)",
        display: "flex",
        "&.loadAnimate": {
            transform: "scale(1) !important"
        }
    },
    title: {
        textAlign: "left"
    },
    toolsContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    menuContainer: {
        transition: "transform 0.1s",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 50,
        width: 50,
        "&:hover": {
            transition: "transform 0.1s",
            cursor: "pointer",
            opacity: 0.75
        },
        "&:active": {
            transform: "translateY(2px)",
            transition: "transform 0.1s"
        }
    },
    menu: {
    }
}, {
    name: "Project-Header"
});
export default useStyles;
