import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const TwitterIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    style,
    onClick,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        className={className}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="M23.61 10.605a6.104 6.104 0 0 1-1.839.537 3.393 3.393 0 0 0 1.408-1.89 6.158 6.158 0 0 1-2.034.829 3.149 3.149 0 0 0-1.817-1.036 3.032 3.032 0 0 0-2.033.36 3.338 3.338 0 0 0-1.407 1.604 3.624 3.624 0 0 0-.2 2.187 8.656 8.656 0 0 1-3.656-1.036 9.23 9.23 0 0 1-2.945-2.532 3.587 3.587 0 0 0-.433 1.716c0 .562.13 1.116.378 1.612s.608.918 1.046 1.23a3.044 3.044 0 0 1-1.45-.427v.044c0 .789.255 1.553.724 2.163a3.176 3.176 0 0 0 1.845 1.185 3.031 3.031 0 0 1-1.447.059 3.419 3.419 0 0 0 1.14 1.697c.536.424 1.184.66 1.852.673A6.178 6.178 0 0 1 8 20.995a8.654 8.654 0 0 0 4.909 1.534c5.891 0 9.112-5.203 9.112-9.714 0-.147-.003-.295-.01-.442a6.764 6.764 0 0 0 1.598-1.766l.001-.002Z"
            fill={color}
        />
    </svg>;
};
export default TwitterIcon;
