import {
    INCoreIconProps
} from "ncore-web";
import {
    CSSProperties 
} from "react";

const GithubIcon = ({
    size = 1,
    color = "#000",
    secondaryColor = "#fff",
    className,
    style,
    onClick,
    ...props
}: INCoreIconProps & {
    secondaryColor?: string;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}) => {
    return <svg
        width={30}
        height={30}
        fill="none"
        style={{
            transform: `scale(${size})`,
            ...style
        }}
        className={className}
        onClick={onClick}
        {...props}
    >
        <rect width={30} height={30} rx={15} fill={secondaryColor} />
        <path
            d="M11.937 21.44C10.25 22 8.562 20.88 8 20.32M12.5 22s-.563-2.24.562-3.36c0 0-2.25 0-3.937-1.68-1.687-1.68-1.125-5.04 0-6.16-.563-1.68.562-2.8.562-2.8s1.687 0 2.812 1.12c1.125-.56 3.937-.56 5.062 0C18.686 8 20.373 8 20.373 8s1.125 1.12.562 2.8c1.125 1.12 1.687 4.48 0 6.16-1.687 1.68-3.937 1.68-3.937 1.68 1.125 1.12.563 3.36.563 3.36h-5.062Z"
            stroke={color}
            strokeWidth={1.875}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>;
};
export default GithubIcon;
