import {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Container,
    Button,
    Text
} from "ncore-web";
import {
    NIcon
} from "../../../../assets/svgr";
import $ from "jquery";
import {
    goTo 
} from "../../../../utils";
import ILandingAbout from "./props";

const BOUBLES = [
    {
        speed: 2,
        size: 1,
        style: {
            left: -200,
            top: 40
        }
    },
    {
        speed: 5,
        size: 0.5,
        style: {
            left: -350,
            top: -80
        }
    },
    {
        speed: 10,
        size: 1,
        style: {
            left: -275,
            top: 0
        }
    }
];

const About = ({
    scrollData,
    dimensions
}: ILandingAbout) => {
    const {
        localize
    } = useNCoreLocalization();
    const {
        activeTheme,
        spaces,
        colors
    } = useNCoreTheme();

    const classes = useStyles();
    const boublesContainerRef = useRef(null);
    const contentRef = useRef(null);

    const [isContentReady, setIsContentReady] = useState(false);

    useEffect(() => {
        $(`#bouble-${BOUBLES.length -1}`).ready(() => {
            setIsContentReady(true);
        });
        if(dimensions.width < 1200) {
            $([boublesContainerRef.current]).addClass("active");
        }
    }, []);

    useEffect(() => {
        if(isContentReady && dimensions.width > 1200) {
            const {
                scrollHeight,
                offsetHeight,
                scrollTop
            } = scrollData;

            const elementVisible = 0;

            const elementTop = $([boublesContainerRef.current]).position().top;
            const percent = ((elementTop - elementVisible) * 100) / scrollHeight;
            const scrollTotal = scrollHeight - offsetHeight;
            const scrollPosition = (scrollTotal * percent) / 100;

            if (scrollTop > scrollPosition) {
                $([boublesContainerRef.current]).addClass("active");
                $([contentRef.current]).addClass("active");
            } else {
                $([boublesContainerRef.current]).removeClass("active");
                $([contentRef.current]).removeClass("active");
            }
        } else {
            $([boublesContainerRef.current]).addClass("active");
            $([contentRef.current]).addClass("active");
        }
    }, [scrollData]);

    const boubleColor = activeTheme === "light" ? colors.body : colors.primary;

    return <Container
        id="about"
        contentClassName={classes.container}
        contentContainerClassName={classes.ncoreContainer}
    >
        <div
            className={classes.contentContainer}
            style={{
                paddingRight: dimensions.width < 1200 ? spaces.container * 2 :  spaces.container * 4,
                paddingLeft: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4
            }}
        >
            <div
                ref={boublesContainerRef}
                className={classes.bouble}
                style={{
                    filter: `drop-shadow(0px 0px 50px ${boubleColor})`,
                    backgroundColor: `${boubleColor}20`
                }}
            >
                {BOUBLES.map((item, index) => {
                    return <div
                        id={`bouble-${index}`}
                        key={`bouble-${index}`}
                        style={{
                            animation: `boublerotate ${item.speed}s linear infinite`
                        }}
                    >
                        <div
                            style={{
                                ...item.style,
                                backgroundColor: `${boubleColor}33`,
                                borderColor: `${boubleColor}99`,
                                transform: `scale(${item.size})`
                            }}
                        ></div>
                    </div>;
                })}
                <NIcon
                    color={colors.body}
                    size={2}
                    style={{
                        position: "absolute"
                    }}
                />
            </div>
            <div
                style={{
                    marginBottom: spaces.content * 4,
                    marginTop: spaces.content * 6
                }}
            >
                <Text
                    className={classes.title}
                    variant="header2"
                    style={{
                        borderBottomColor: colors.primary,
                        paddingRight: spaces.inline * 8,
                        paddingBottom: spaces.inline
                    }}
                >
                    {localize("landing-about-section-title")}
                </Text>
            </div>
            <div
                ref={contentRef}
                className={classes.content}
            >
                <Text
                    style={{
                        marginBottom: spaces.content * 4
                    }}
                >
                    <strong>{localize("about-content-1")}</strong><br />
                    <br/>
                    {localize("about-content-2")}<br />
                    <br />
                    {localize("about-content-3")}
                </Text>
                <Button
                    title={localize("about-button-text")}
                    size="medium"
                    onClick={() => {
                        goTo("/#services");
                    }}
                />
            </div>
        </div>
    </Container>;
};
export default About;
