import {
    INCoreIconProps
} from "ncore-web";

const MoonIcon = ({
    size = 1,
    color = "#000",
    ...props
}: INCoreIconProps) => {
    return <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`
        }}
        {...props}
    >
        <path
            d="M8.4 4.208a.583.583 0 0 1 .059.644 5.504 5.504 0 0 0-.644 2.595c0 3.016 2.404 5.458 5.366 5.458.387 0 .763-.042 1.125-.12a.566.566 0 0 1 .594.237.559.559 0 0 1-.023.67 6.146 6.146 0 0 1-2.124 1.704c-.823.399-1.723.605-2.634.604C6.739 16 4 13.214 4 9.782a6.221 6.221 0 0 1 3.758-5.738.54.54 0 0 1 .642.164Z"
            fill={color}
        />
        <path
            d="M12.096 6.447a.168.168 0 0 1 .06-.083.159.159 0 0 1 .19 0 .17.17 0 0 1 .06.083l.29.904c.13.403.434.72.822.854l.872.3c.032.012.06.033.08.062a.173.173 0 0 1 0 .198.164.164 0 0 1-.08.061l-.872.301a1.295 1.295 0 0 0-.508.326c-.143.148-.25.329-.314.527l-.29.904a.168.168 0 0 1-.06.084.159.159 0 0 1-.19 0 .168.168 0 0 1-.06-.084l-.29-.904a1.356 1.356 0 0 0-.314-.527 1.294 1.294 0 0 0-.509-.326l-.871-.3a.164.164 0 0 1-.081-.062.173.173 0 0 1 0-.198.164.164 0 0 1 .08-.061l.872-.301c.192-.067.366-.178.509-.326.143-.148.25-.329.314-.528l.29-.904Zm2.302-2.371a.113.113 0 0 1 .04-.055.106.106 0 0 1 .126 0 .112.112 0 0 1 .04.055l.193.602c.086.269.29.48.55.57l.58.2a.11.11 0 0 1 .053.041.116.116 0 0 1 0 .13.11.11 0 0 1-.053.042l-.58.2a.863.863 0 0 0-.34.218.905.905 0 0 0-.21.352l-.193.602a.112.112 0 0 1-.04.055.106.106 0 0 1-.126 0 .113.113 0 0 1-.04-.055l-.193-.602a.905.905 0 0 0-.21-.352.863.863 0 0 0-.34-.217l-.58-.2a.11.11 0 0 1-.053-.042.116.116 0 0 1 0-.13.11.11 0 0 1 .053-.042l.58-.2c.26-.09.463-.301.55-.57l.193-.601v-.001Z"
            fill={color}
        />
    </svg>;
};
export default MoonIcon;
