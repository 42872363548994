import {
    useEffect,
    useState
} from "react";
import useStyles from "./style";
import {
    useNCoreLocalization,
    useNCoreTheme,
    Container,
    Text
} from "ncore-web";
import TeamCard from "./components/teamCard";
import IOurTeam, {
    TeamMember 
} from "./props";
import $ from "jquery";

const TEAM: Array<TeamMember> = [
    {
        fullName: "Furkan Atakan BOZKURT",
        title: "landing-our-team-section-fab-title",
        appellation: "root",
        profileImage: "fabmini.png",
        socialLinks: [
            {
                icon: "linkedin",
                url: "https://linkedin.com/in/lfabl"
            },
            {
                icon: "github",
                url: "https://github.com/lfabl"
            },
            {
                icon: "upwork",
                url: "https://www.upwork.com/freelancers/~01860b13afff1cef42"
            },
            {
                icon: "medium",
                url: "https://furkanatakanbozkurt.medium.com/"
            },
            {
                icon: "facebook",
                url: "https://facebook.com/lfabl"
            },
            {
                icon: "twitter",
                url: "https://twitter.com/ll_fab_ll"
            },
            {
                icon: "instagram",
                url: "https://instagram.com/furkan_atakan_bozkurt"
            }
        ],
        website: {
            url: "https://furkanatakanbozkurt.nibgat.com",
            title: "FAB - Personal Sharing System"
        }
    },
    {
        fullName: "Mahmut PEKER",
        title: "landing-our-team-section-mp-title",
        appellation: "cfo",
        profileImage: "mpmini.jpg",
        socialLinks: [
            {
                icon: "linkedin",
                url: "https://www.linkedin.com/in/mahmut-peker-793731162/"
            },
            {
                icon: "github",
                url: "https://github.com/mrfare"
            },
            {
                icon: "instagram",
                url: "https://www.instagram.com/mpeker46/"
            }
        ],
    },
    {
        fullName: "Hüseyin ATLI",
        title: "landing-our-team-section-ha-title",
        appellation: "assistant",
        profileImage: "hamini.png",
        socialLinks: [
            {
                icon: "linkedin",
                url: "https://www.linkedin.com/in/h%C3%BCseyin-atl%C4%B1-780180226/"
            },
            {
                icon: "twitter",
                url: "https://twitter.com/hsyn_atl7"
            },
            {
                icon: "instagram",
                url: "https://www.instagram.com/hsyn_atl7/"
            }
        ],
    }
];

const OurTeam = ({
    scrollData,
    dimensions
}: IOurTeam) => {
    const {
        spaces,
        colors
    } = useNCoreTheme();
    const {
        localize
    } = useNCoreLocalization();

    const classes = useStyles();

    const [isReadyTeamMemberDatas, setIsReadyTeamMemberDatas] = useState(false);

    useEffect(() => {
        setAnimations();
        $(`#team-member-id-${TEAM.length - 1}`).ready(() => {
            if(!isReadyTeamMemberDatas) setIsReadyTeamMemberDatas(true);
        });
        if(dimensions.width < 1200) {
            for (var i = 0; i < TEAM.length; i++) {
                $(`#team-member-id-${i}`).addClass("active");
            }
        }
    }, []);

    useEffect(() => {
        setAnimations();
    }, [scrollData]);

    const setAnimations = () => {
        if(dimensions.width > 1200) {
            const {
                scrollHeight,
                offsetHeight,
                scrollTop
            } = scrollData;

            const elementVisible = 150;

            if(isReadyTeamMemberDatas) {
                for (var i = 0; i < TEAM.length; i++) {
                    const elementTop = $(`#team-member-id-${i}`).position().top;
                    const percent = ((elementTop - elementVisible) * 100) / scrollHeight;
                    const scrollTotal = scrollHeight - offsetHeight;
                    const scrollPosition = (scrollTotal * percent) / 100;

                    if (scrollTop > scrollPosition) {
                        $(`#team-member-id-${i}`).addClass("active");
                    } else {
                        $(`#team-member-id-${i}`).removeClass("active");
                    }
                }
            }
        } else {
            for (var i = 0; i < TEAM.length; i++) {
                $(`#team-member-id-${i}`).addClass("active");
            }
        }
    };

    return <Container
        id="team"
        contentClassName={[
            classes.container
        ].join(" ")}
        contentContainerStyle={{
            paddingRight: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4,
            paddingLeft: dimensions.width < 1200 ? spaces.container * 2 : spaces.container * 4
        }}
    >
        <div
            className={classes.titleContainer}
            style={{
                marginBottom: spaces.content * 4,
                marginTop: spaces.container * 2
            }}
        >
            <Text
                className={classes.title}
                variant="header2"
                style={{
                    borderBottomColor: colors.primary,
                    marginBottom: spaces.content * 2,
                    paddingRight: spaces.inline * 8,
                    paddingBottom: spaces.inline
                }}
            >
                {localize("landing-our-team-section-title")}
            </Text>
            <Text>
                {localize("landing-our-team-section-description")}<br />
                {localize("landing-our-team-section-description-2")}
            </Text>
        </div>
        <div
            className={classes.contentContainer}
        >
            {
                TEAM.map((item, index) => {
                    return <TeamCard
                        id={`team-member-id-${index}`}
                        key={`team-member-${index}`}
                        fullName={item.fullName}
                        title={item.title}
                        appellation={item.appellation}
                        profileImage={item.profileImage}
                        socialLinks={item.socialLinks}
                        dimensions={dimensions}
                    />;
                })
            }
        </div>
    </Container>;
};
export default OurTeam;
