import {
    LocaleConfig
} from "ncore-web/lib/core/constants";

export const en = require("./variants/en.json");
export const tr = require("./variants/tr.json");

const locales: LocaleConfig[] = [
    tr,
    en
];
export default locales;
