import {
    INCoreIconProps
} from "ncore-web";

const SunIcon = ({
    size = 1,
    ...props
}: INCoreIconProps) => {
    return <svg
        width={19}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: `scale(${size})`
        }}
        {...props}
    >
        <path
            d="M6.25 18.219v-4.313l-3.219 2.375c.906.844 2 1.5 3.219 1.938m6.25 0a9.332 9.332 0 0 0 3.219-1.938L12.5 13.875v4.344M.531 12.5c.094.313.219.594.375.906l1.219-.906H.531Zm16.094 0 1.219.906c.125-.281.25-.594.375-.906h-1.594Z"
            fill="#2A5F9E"
        />
        <path
            d="M6.719 11.25H.187c.094.438.22.844.344 1.25h1.594l-1.219.906c.25.531.531 1 .875 1.469L5 12.5h1.25v.625l-3.656 2.688.437.437 3.219-2.344v4.313c.406.156.813.25 1.25.343V11.25h-.781Zm11.843 0H11.25v7.313c.438-.094.844-.22 1.25-.344v-4.313l3.219 2.344c.437-.406.812-.844 1.187-1.313L13.563 12.5h2.124l1.907 1.406c.093-.156.187-.344.25-.5l-1.219-.906h1.594a14.6 14.6 0 0 0 .343-1.25"
            fill="#fff"
        />
        <path
            d="M1.781 14.875c.25.344.5.656.782.969l3.687-2.688v-.625H5l-3.219 2.344ZM13.594 12.5l3.344 2.438c.125-.157.218-.313.343-.47.032-.03.032-.062.063-.062.093-.156.218-.344.312-.5L15.688 12.5h-2.094Z"
            fill="#ED4C5C"
        />
        <path
            d="M12.5.531v4.313l3.219-2.375c-.906-.844-2-1.5-3.219-1.938Zm-6.25 0A9.333 9.333 0 0 0 3.031 2.47L6.25 4.875V.531ZM18.219 6.25a5.552 5.552 0 0 0-.375-.906l-1.219.906h1.594m-16.094 0L.906 5.344c-.125.312-.25.593-.375.906h1.594Z"
            fill="#2A5F9E"
        />
        <path
            d="M12.031 7.5h6.5c-.093-.438-.218-.844-.343-1.25h-1.594l1.218-.906c-.25-.532-.53-1-.875-1.469L13.75 6.25H12.5v-.625l3.656-2.688-.437-.437L12.5 4.844V.53c-.406-.156-.813-.25-1.25-.344V7.5h.781ZM.187 7.5H7.5V.187c-.438.094-.844.22-1.25.344v4.313L3.031 2.5c-.437.406-.812.844-1.187 1.313L5.187 6.25H3.063L1.157 4.844a3.73 3.73 0 0 0-.25.5l1.219.906H.531c-.125.406-.25.813-.344 1.25Z"
            fill="#fff"
        />
        <path
            d="M16.969 3.875c-.25-.344-.5-.656-.782-.969L12.5 5.594v.625h1.25l3.219-2.344M5.156 6.25 1.844 3.812c-.125.157-.219.313-.344.47-.031.03-.031.062-.063.062-.093.156-.218.343-.312.5L3.031 6.25h2.125Z"
            fill="#ED4C5C"
        />
        <path
            d="M18.563 7.5H11.25V.187A9.084 9.084 0 0 0 9.375 0C8.719 0 8.094.063 7.5.188V7.5H.187A9.084 9.084 0 0 0 0 9.375c0 .656.063 1.281.188 1.875H7.5v7.313a9.084 9.084 0 0 0 1.875.187c.656 0 1.281-.063 1.875-.188V11.25h7.313a9.084 9.084 0 0 0 .187-1.875c0-.656-.063-1.281-.188-1.875Z"
            fill="#ED4C5C"
        />
    </svg>;
};
export default SunIcon;
