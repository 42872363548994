import {
    createUseStyles
} from "react-jss";
import "./style.css";

const useStyles = createUseStyles({
    container: {
        height: "100%",
        width: "100%"
    },
    "@global": {
        "*": {
            padding: 0,
            margin: 0
        },
        "html, body, #root": {
            scrollBehavior: "smooth !important"
        },
        "html::-webkit-scrollbar, body::-webkit-scrollbar, #root::-webkit-scrollbar": {
            display: "none"
        }
    }
}, {
    name: "Project-Main"
});
export default useStyles;
